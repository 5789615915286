import InputField from "./InputField";
import PasswordField from "./PasswordField";
import Button from "./Button";
import SelectField from "./SelectField";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import DialogBox from "./DialogBox";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { waitForElement } from "../utility/ElementsService";
// Component SignUp
function SignUp() {
  const dialogBoxRef = useRef(null);
  const url = process.env.REACT_APP_IN_STATES_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [statesDetails, setStatesDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [cityLoading, setCityLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [districtDetails, setDistrictDetails] = useState({});
  const state = useRef(null);
  const district = useRef(null);
  const [stateValue, setStateValue] = useState("Select State");
  const [districtValue, setDistrictValue] = useState("Select District");
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    mobile: "",
    state: "",
    district: "",
    city: "",
    password: "",
    confirmPassword: "",
  });
  const passwordChecker = useRef(null);
  const [dialogBox, setDialogBox] = useState({});

  const menuOptionsProps = [
    {
      name: "Sign In",
      address: "/signin",
    },
    {
      name: "Services",
      address: "/services",
    },
    {
      name: "About",
      address: "/about",
    },

    {
      name: "Contact",
      address: "/contact",
    },
  ];

  // fetch details of states of India
  useEffect(() => {
    async function fetchStateDetails() {
      await axios
        .get(`${url}/states`)
        .then((response) => {
          setStatesDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setErr(error); // Handle any errors
          setLoading(false);
        });
    }
    fetchStateDetails();
  }, []);

  // fetching details of district depends on selected state
  const fetchDistrictDetails = async function (event) {
    console.log(statesDetails);
    event.preventDefault();
    setStateValue(event.target.innerText);
    setUserDetails((prev) => ({ ...prev, ["state"]: event.target.innerText }));
    if (state.current) {
      state.current.menuToggle(); //calling function which defined inside SelectField Component by using useImperativeHandle Hook
    }
    await axios
      .get(`${url}/districts/:${event.target.id}`)
      .then((response) => {
        setDistrictDetails(response.data);
        setCityLoading(false);
      })
      .catch((error) => {
        setErr(error); // Handle any errors
        setCityLoading(false);
      });
    setDistrictValue("Select District");
  };

  const setDistrict = function (event) {
    setDistrictValue(event.target.innerText);
    setUserDetails((prev) => ({
      ...prev,
      ["district"]: event.target.innerText,
    }));
    if (district.current) {
      district.current.menuToggle(); //calling function which defined inside SelectField Component by using useImperativeHandle Hook
    }
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function validateMobile(mobile) {
    const regex = /^\d{10}$/;
    return regex.test(mobile);
  }

  const alignMent = {
    alignSelf: "end",
    position: "relative",
    right: "10px",
  };
  const handleSubmit = function (event) {
    event.preventDefault();
    if (userDetails.fullName === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "name",
        title: "Name is empty",
        definition: "Please fill your name",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.email === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "email",
        title: "E-mail is empty",
        definition: "Please fill your email",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (!validateEmail(userDetails.email)) {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "email",
        title: "E-mail is invalid",
        definition: "Please enter valid email",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.mobile === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "mobile",
        title: "Mobile number is empty",
        definition: "Please fill your mobile number",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (!validateMobile(userDetails.mobile)) {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "mobile",
        title: "Mobile number is invalid",
        definition: "Please enter valid mobile number",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.state === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "state",
        title: "State is empty",
        definition: "Please select your state",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.district === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "district",
        title: "District is empty",
        definition: "Please select your district",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.city === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "city",
        title: "City is empty",
        definition: "Please fill your city",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.password === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "password",
        title: "Password is empty",
        definition: "Please fill your password",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else if (userDetails.email === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "confirmPassword",
        title: "Confirm Password is empty",
        definition: "Please fill your comfirm password",
        buttonTitle: "OK",
        navigate: null,
      });
      dialogBox.style.display = "block";
    } else {
      setLoading(true);
      axios
        .post(`${apiUrl}/register`, userDetails, { withCredentials: true })
        .then(async (response) => {
          console.warn(response);
          setLoading(false);
          if (response.status === 200) {
            const dialogBox = await waitForElement("#dialogBox");
            console.warn(dialogBox);
            setDialogBox({
              type: "greeting",
              title: "Welcome to BeautyOne",
              definition: "",
              buttonTitle: "Welcome",
              navigate: "/signin",
            });
            dialogBox.style.display = "block";
          } else {
            navigate("/signup");
          }
        })
        .catch(async (err) => {
          setLoading(false);
          console.log(err);
          if (err.response.status === 401) {
            const dialogBox = await waitForElement("#dialogBox");
            setDialogBox({
              type: "Issue",
              title: "Some 401 error",
              definition: "Something went wrong please try again later!!!",
              buttonTitle: "OK",
            });
            dialogBox.style.display = "block";
            setUserDetails({
              fullName: "",
              email: "",
              mobile: "",
              state: "",
              district: "",
              city: "",
              password: "",
              confirmPassword: "",
            });
          } else if (err.response.status === 409) {
            const dialogBox = await waitForElement("#dialogBox");
            setDialogBox({
              type: "conflict",
              title: err.response.data.status,
              definition: err.response.data.message,
              buttonTitle: "OK",
              navigate: "/signin",
            });
            dialogBox.style.display = "block";
          } else {
            const dialogBox = await waitForElement("#dialogBox");
            setDialogBox({
              type: "Issue",
              title: "Some error",
              definition: "Something went wrong please try again later!!!",
              buttonTitle: "OK",
            });
            dialogBox.style.display = "block";
            console.log(err);
          }
        });
    }

    // Sending data to server

    event.preventDefault();

    // const userJson = JSON.stringify(userDetails);
    // console.log(userJson);
  };

  // Log initial state
  // useEffect(() => {

  //   console.log("Initial userDetails:", userDetails);
  // }, [userDetails, stateValue]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (!(name === "state") && !(name === "district")) {
      setUserDetails((prev) => ({ ...prev, [name]: value }));
    }
    if (name === "confirmPassword") {
      if (value === userDetails.password) {
        passwordChecker.current.style.display = "none";
      } else {
        passwordChecker.current.style.display = "block";
      }
    }
    event.preventDefault();
  };

  if (loading) return <Loader />;
  if (err) return <h1>Tech issue...</h1>;

  return (
    <div>
      <Header menuOptionsProps={menuOptionsProps} />
      <form id="sign_in">
        <div style={{ margin: "100px 0 10px 0" }}>
          <h1>Sign Up</h1>
          <div>
            <InputField
              id="fullname"
              name="fullName"
              input="text"
              label="Full Name"
              placeholder="Full Name"
              value={userDetails.fullName}
              onChange={handleChange}
            />
            <InputField
              id="email"
              name="email"
              input="email"
              label="E-mail"
              placeholder="E-mail"
              value={userDetails.email}
              onChange={handleChange}
            />
            <InputField
              id="mobileNumber"
              name="mobile"
              input="phone"
              label="Mobile Number"
              placeholder="Mobile Number"
              value={userDetails.mobile}
              onChange={handleChange}
            />
            <SelectField
              label="State"
              name="state"
              placeholder={stateValue}
              ref={state}
              options={[statesDetails, "state"]}
              handleClick={fetchDistrictDetails}
              value={userDetails.state}
              onChange={handleChange}
            />
            {stateValue != "Select State" && (
              <SelectField
                label="District"
                name="district"
                placeholder={!cityLoading ? districtValue : "Loading..."}
                ref={district}
                options={[districtDetails, "city"]}
                handleClick={setDistrict}
                value={userDetails.district}
                onChange={handleChange}
              />
            )}
            <InputField
              id="city"
              name="city"
              input="text"
              label="City"
              placeholder="City"
              value={userDetails.city}
              onChange={handleChange}
            />
            <PasswordField
              id="password"
              name="password"
              label="Password"
              placeholder="Password"
              value={userDetails.password}
              onChange={handleChange}
            />
            <PasswordField
              id="confirmpassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              label="Confirm Password"
              value={userDetails.confirmPassword}
              onChange={handleChange}
            />
            <p id="passwordChecker" ref={passwordChecker}>
              Mismatched password
            </p>
          </div>
          <Button style={alignMent} title="Sign Up" onClick={handleSubmit} />
          <div id="circle"></div>
          <DialogBox
            type={dialogBox.type}
            title={dialogBox.title}
            definition={dialogBox.definition}
            buttonTitle={dialogBox.buttonTitle}
            navigate={dialogBox.navigate}
          />
        </div>
      </form>
      <Footer />
      <br />
    </div>
  );
}
export default SignUp;
