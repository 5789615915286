import "../style/contact.css";
import Header from "./Header";
import Footer from "./Footer";

function Contact() {
  const menuOptionsProps = [
    {
      name: "Sign In",
      address: "/signin",
    },
    {
      name: "Sign Up",
      address: "/signup",
    },
    {
      name: "Services",
      address: "/services",
    },
    {
      name: "About",
      address: "/about",
    },
  ];
  return (
    <div id="contact">
      <Header menuOptionsProps={menuOptionsProps} />
      <div id="contact_img"></div>
      <div id="contact_content">
        <h1>Contact Us</h1>
        <br />
        <h6>
          Welcome to BeautyOne
          <br />– A Beauty Destination!
        </h6>
        <br />
        <p>
          <strong>
            <i class="fa-solid fa-location-crosshairs"></i> Location:{" "}
          </strong>
          <a href="https://maps.app.goo.gl/bzG7c36Tvmc3pKPx8" target="_blank">
            BeautyOne, Ward # 15, near city center, Jai Gurudev Nagar, Ganj
            Basoda, dist: Vidisha(464221), Madhya-Pradesh
          </a>
        </p>
        <p>
          <ul>
            <li>
              <strong>
                <i class="fa-solid fa-phone"></i> Phone:
              </strong>{" "}
              <a href="tel:+917489882901" target="_blank">
                7489882901
              </a>
            </li>
            <li>
              <strong>
                <i class="fa-regular fa-envelope"></i> Email:
              </strong>{" "}
              <a href="mailto:beautyone.co.in@gmail.com" target="_blank">
                beautyone.co.in@gmail.com
              </a>
            </li>
            <li>
              <strong>
                <i class="fa-brands fa-instagram"></i> Instagram:
              </strong>{" "}
              <a
                href="https://www.instagram.com/beautyone_makeup_jewellery?igsh=ZnJudDk1aHZxOTF0"
                target="_blank"
              >
                BeautyOne_MakeUp_Jewellery
              </a>
            </li>
            <li>
              <strong>
                <i class="fa-brands fa-whatsapp"></i> WhatsApp:
              </strong>{" "}
              <a href="https://wa.me/+917489882901" target="_blank">
                Chat with us
              </a>
            </li>
          </ul>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
