import { useContext, useEffect, useRef, useState } from "react";
import "../style/yourbooking.css";
import axios from "axios";
import { HorizontalDivider } from "./HorizontalDivider";
import { UserContext } from "../context/UserContext";
const apiUrl = process.env.REACT_APP_API_URL;

export function YourBooking(props) {
  const [userBookings, setUserBookings] = useState({});
  const [loader, setLoader] = useState(true);
  const { userDetailsContext } = useContext(UserContext);
  const yourBooking = useRef(null);
  // console.log(yourBooking)
  function closeyourBooking() {
    yourBooking.current.style.display = "none";
  }
  // Close the modal if clicking outside of the modal content
  window.onclick = function (event) {
    if (event.target === yourBooking.current) {
      yourBooking.current.style.display = "none";
    }
  };

  useEffect(() => {
    const bookings = async function () {
      try {
        const response = await axios.get(
          `${apiUrl}/protected/bookings/${userDetailsContext.user_id}`,
          {
            withCredentials: true,
          }
        );
        setUserBookings(response.data);
        setLoader(false);
      } catch (err) {
        console.log(err);
      }
    };
    bookings();
  }, []);

  if (loader) return <div>Loading...</div>;
  return (
    // Popup dialog box
    <div ref={yourBooking} id="yourBooking" className="modal-your-booking">
      <div className="modal-content-your-booking">
        <span
          id="closeYourBookigBtn"
          className="close-your-booking"
          onClick={closeyourBooking}
        >
          &times;
        </span>
        <ul>
          {userBookings.size !== 0 &&
            userBookings.bookings.map((booking, index) => {
              return (
                <li id="user-bookings">
                  <p key={index}>{booking.service_name}</p>
                  <p key={index}>{booking.booking_date}</p>
                </li>
              );
            })}
          {userBookings.size === 0 && <li>No bookings</li>}
        </ul>
        <HorizontalDivider></HorizontalDivider>
      </div>
    </div>
  );
}
