import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ShoppingCartCheckoutRoundedIcon from "@mui/icons-material/ShoppingCartCheckoutRounded";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { Badge, Avatar } from "@mui/material";
import { UserContext } from "../context/UserContext.js";
import { useContext } from "react";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Box from "@mui/material/Box";
import { useRef } from "react";
import { CartBadgeContext } from "../context/CartBadgeContext.js";

export function BottomAppBar(props) {
  const { userDetailsContext } = useContext(UserContext);
  const { badgeValue } = useContext(CartBadgeContext);
  const styledFab = useRef(null);
  const StyledFab = styled(Fab)({
    position: "absolute",
    zIndex: 1,
    top: -25,
    left: 0,
    right: 0,
    margin: "0 auto",
    borderRadius: "50",
    color: "#001f3f",
    backgroundColor: "#ead8b1",
    borderRadius: "50% !important",
    width: "3em",
    height: "3em",
  });
  function handleProfileClick() {
    document.getElementById("sideBar").style.display = "inline";
  }
  const profileStyle = {
    fontSize: "0.5em",
    color: "#001f3f",
    border: "2px #001f3f groove",
    borderRadius: "50%",
    backgroundColor: "white",
  };
  const appBarStyle = {
    backgroundColor: "#ffc55a",
  };
  const shoppingCartCheckoutRoundedIconStyle = {
    // Design Icon for cart logo
  };
  function handleClick() {
    styledFab.current.style.backgroundColor = "#ead8b1";
    const showCart = props.showCart;
    showCart();
  }
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{ top: "auto", bottom: 0 }}
        style={appBarStyle}
      >
        <Toolbar>
          <IconButton>
            <CalendarMonthRoundedIcon
              style={{ color: "#001f3f" }}
              onClick={props.showBookingPicker}
            />
          </IconButton>

          <StyledFab
            ref={styledFab}
            color="secondary"
            aria-label="add"
            style={shoppingCartCheckoutRoundedIconStyle}
            onClick={handleClick}
          >
            <Badge
              badgeContent={badgeValue}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              slotProps={{ badge: { className: "my-badge" } }}
            >
              <ShoppingCartCheckoutRoundedIcon />
            </Badge>
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleProfileClick}
          >
            <Avatar
              alt={userDetailsContext.user_name}
              src="../assets/profile.jpg"
              style={profileStyle}
              sx={{ width: 25, height: 25 }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}
