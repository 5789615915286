import { UserContext } from "../context/UserContext";
import { useContext, useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import Footer from "./Footer";
import "../style/home.css";
import ServiceCard from "./ServiceCard";
import { BottomAppBar } from "./BottomAppBar";
import { Cart } from "./Cart.jsx";
import { CartContext } from "../context/CartContext.js";
import BookingPicker from "./BookingPicker.jsx";
import Loader from "./Loader.jsx";
import { YourBooking } from "./YourBooking.jsx";

function Home() {
  const apiUrl = process.env.REACT_APP_API_URL;
  // async function handleSubmit() {
  //   try {
  //     const response = await axios(`${apiUrl}/protected`, {
  //       withCredentials: true,
  //     });
  //     console.log(response);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  const { userDetailsContext } = useContext(UserContext);
  const { cartDetails } = useContext(CartContext);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const cart = useContext(CartContext);
  const buttonStyle = {
    width: "max-content",
    justifySelf: "center",
  };

  useEffect(() => {
    const categories = async function () {
      try {
        const response = await axios.get(
          `${apiUrl}/protected/service-categories`,
          {
            withCredentials: true,
          }
        );
        setServiceDetails([...response.data.cartDetails]);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    categories();
  }, []);

  function handleCartButtonClick() {
    document.getElementById("sideBar").style.display = "none";
    const cart = document.getElementById("cart");
    if (cart.style.display === "none" || cart.style.display === "") {
      if (cartDetails.bookingTime === "") {
        document.getElementById("booking_picker").style.display = "block";
      }
      cart.style.display = "flex";
    } else {
      cart.style.display = "none";
    }
  }
  function handleBookingPickerClick() {
    const bookingPicker = document.getElementById("booking_picker");
    if (
      bookingPicker.style.display === "none" ||
      bookingPicker.style.display === ""
    ) {
      bookingPicker.style.display = "block";
    } else {
      bookingPicker.style.display = "none";
    }
  }
  function handleYourBookingButtonClick() {
    document.getElementById("sideBar").style.display = "none";
    const yourBooking = document.getElementById("yourBooking");
    if (
      yourBooking.style.display === "none" ||
      yourBooking.style.display === ""
    ) {
      yourBooking.style.display = "flex";
    } else {
      yourBooking.style.display = "none";
    }
  }
  if (loading) return <Loader />;
  return (
    <div id="home">
      <Header
        auth={true}
        userName={userDetailsContext.user_name}
        showCart={handleCartButtonClick}
        showYourBooking={handleYourBookingButtonClick}
      ></Header>
      {serviceDetails.map((category, index) => (
        <div key={index} id="service">
          <span key={index + 1}>{category.service_category_name}</span>
          <div key={index + 2} id="service_container">
            {category.details.map((service, index) => (
              <ServiceCard
                key={"S" + index}
                serviceId={service.service_id}
                // price={service.price}
                name={service.service_name}
              />
            ))}
          </div>
        </div>
      ))}

      {/* <Button title={"Go to cart"} style={buttonStyle} /> */}
      <BookingPicker buttonTitle={"Continue"} />
      <YourBooking />
      <Cart cart={cart} buttonTitle={"Book"} />
      <BottomAppBar
        showBookingPicker={handleBookingPickerClick}
        showCart={handleCartButtonClick}
      />
    </div>
  );
}

export default Home;
