import React, { createContext, useState, useEffect } from "react";

// Create context
const CartContext = createContext();

// Create provider component
function CartProvider({ children }) {
  const [cartDetails, setCartDetails] = useState(() => {
    // Retrieve data from localStorage if it exists
    const savedCardDetails = localStorage.getItem("cartDetails");
    return savedCardDetails
      ? JSON.parse(savedCardDetails)
      : {
          userId: "",
          bookingTime: "",
          serviceDetails: [],
          totalAmount: 0
        };
  });

  useEffect(() => {
    // Save data to localStorage whenever it changes
    if (cartDetails) {
      localStorage.setItem("cartDetails", JSON.stringify(cartDetails));
    }
  }, [cartDetails]);

  return (
    <CartContext.Provider value={{ cartDetails, setCartDetails }}>
      {children}
    </CartContext.Provider>
  );
}

export { CartContext, CartProvider };
