import Welcome from "./components/Welcome";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import { UserProvider } from "./context/UserContext";
import Cookies from "js-cookie";
import Home from "./components/Home";
import { CartBadgeProvider } from "./context/CartBadgeContext";
import Loader from "./components/Loader";
import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "./AuthContext";
import { CartProvider } from "./context/CartContext";

function App() {
  const { isAuthenticated, loading } = useAuth();
  if (loading) return <Loader />; // Render a loading screen until auth check completes
  return (
    <UserProvider>
      <CartBadgeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/welcome" element={<Welcome />}></Route>
            <Route
              path="/home"
              element={
                isAuthenticated ? (
                  <CartProvider>
                    <Home />
                  </CartProvider>
                ) : (
                  <Navigate to="/welcome" />
                )
              }
            ></Route>

            <Route path="/signin" element={<SignIn />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/" element={<Navigate to="/home" />} />
          </Routes>
        </BrowserRouter>
      </CartBadgeProvider>
    </UserProvider>

    // <Loader />
  );
}

export default App;
