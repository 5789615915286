import React, { createContext, useState, useEffect } from 'react';

// Create context
const UserContext = createContext();

// Create provider component
function UserProvider({ children }) {
  
  const [userDetailsContext, setUserDetailsContext] = useState(() => {
    // Retrieve data from localStorage if it exists
    const savedData = localStorage.getItem("userDetails");
    return savedData ? JSON.parse(savedData) : null;
  });
  
  useEffect(() => {
    
    // Save data to localStorage whenever it changes
    if (userDetailsContext) {
      localStorage.setItem("userDetails", JSON.stringify(userDetailsContext));
    }
  }, [userDetailsContext]);

  return (
    <UserContext.Provider value={{ userDetailsContext, setUserDetailsContext }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
