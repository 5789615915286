import "../style/about.css";
import Footer from "./Footer.jsx";
import Header from "./Header.jsx";

function About() {
  const menuOptionsProps = [
    {
      name: "Sign In",
      address: "/signin",
    },
    {
      name: "Sign Up",
      address: "/signup",
    },
    {
      name: "Services",
      address: "/services",
    },
    {
      name: "Contact",
      address: "/contact",
    },
  ];
  return (
    <div id="about">
        <Header menuOptionsProps={menuOptionsProps}/>
      <div id="about_img"></div>
      <div id="about_content">
        <h1>About Us</h1>
        <br />
        <h6>
          Welcome to BeautyOne
          <br />– A Beauty Destination!
        </h6>
        <br />
        <p>
          At <strong>BeautyOne</strong>, we believe that true beauty comes from feeling
          confident, pampered, and radiant. Our mission is to provide every
          guest with exceptional service in a relaxing and luxurious
          environment, where you can unwind and leave feeling your very best.
        </p>
        <div id="card_grid">
          {/* <br /> */}
          <div>
            <h6>Our Story</h6>
            <p>
              Founded in <strong>2024</strong>, <strong>BeautyOne</strong> has
              become a trusted name in the world of beauty and wellness. We
              started with a simple idea: to create a space where everyone could
              indulge in high-quality beauty treatments and experience
              personalized care. Over the years, we've grown thanks to the trust
              and love of our clients and our commitment to staying ahead of
              trends and techniques in the beauty industry.
            </p>
          </div>
          {/* <br /> */}
          <div>
            <h6>What We Offer</h6>
            <p>
              We are excited to offer a wide range of beauty and wellness
              services, including:
              <ul>
                <li>
                  <strong>Hair Care: </strong>
                  <p>
                    Trendy cuts, vibrant color treatments, and custom styling.
                  </p>
                </li>
                <li>
                  <strong>Skincare: </strong>
                  <p>
                    Revitalizing facials, soothing treatments, and personalized
                    skincare solutions.
                  </p>
                </li>
                <li>
                  <strong>Makeup: </strong>
                  <p>
                    Expert makeup services for every occasion, from everyday
                    elegance to bridal beauty.
                  </p>
                </li>
                <li>
                  <strong>Bridal Packages: </strong>
                  <p>
                    Tailored beauty packages to make your big day extraordinary.
                  </p>
                </li>
              </ul>
            </p>
          </div>
          {/* <br /> */}
          <div>
            <h6>Our Commitment to You</h6>
            <p>
              <ul>
                <li>
                  <strong>Exceptional Service: </strong>
                  <p>
                    We are dedicated to making every visit a memorable and
                    delightful experience.
                  </p>
                </li>
                <li>
                  <strong>Premium Products: </strong>
                  <p>
                    We use only the best beauty products, ensuring you receive
                    safe, effective, and luxurious care.
                  </p>
                </li>
                <li>
                  <strong>A Relaxing Atmosphere: </strong>
                  <p>
                    Our beauty parlour has been thoughtfully designed to be your
                    peaceful retreat from daily stress.
                  </p>
                </li>
              </ul>
            </p>
          </div>
          {/* <br /> */}
          <div>
            <h6>Our Promise</h6>
            <p>
              As a newly opened establishment, we are committed to building a
              reputation of trust, quality, and innovation. We invite you to be
              a part of our journey, and we can’t wait to make you look and feel
              beautiful, inside and out.
            </p>
          </div>
        </div>
        <br />
        <br />
        <h6>Visit Us and Discover the Difference!</h6>
      </div>
      <Footer />
    </div>
  );
}

export default About;
