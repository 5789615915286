import "../style/servicecard.css";
import { useContext, useEffect, useRef, useState } from "react";
import { CartBadgeContext } from "../context/CartBadgeContext";
import { UserContext } from "../context/UserContext";
import { CartContext } from "../context/CartContext";
import Button from "./Button";
function ServiceCard(props) {
  const serviceCard = useRef(null);
  const serviceCardFooter = useRef(null);
  const serviceCardSelector = useRef(null);
  const serviceCardButton = useRef(null);
  const { userDetailsContext } = useContext(UserContext);
  const { cartDetails, setCartDetails } = useContext(CartContext);
  const [serviceCardBorderToggler, setServiceCardBorderToggler] =
    useState(false);
  const { badgeValue, setBadgeValue } = useContext(CartBadgeContext); 
  var totalAmount = 0;
  // Function to add new service
  function addService(newServiceId, newServicePrice, newServiceName) {
    setCartDetails((prevData) => ({
      ...prevData,
      userId: userDetailsContext.user_id,
      serviceDetails: [
        ...prevData.serviceDetails,
        {
          serviceId: newServiceId,
          price: newServicePrice,
          name: newServiceName,
        },
      ],
      totalAmount: (prevData.totalAmount + newServicePrice),
    }));
  }
  // Function to remove service by its id
  function removeService(removeService,price) {
    setCartDetails((prevData) => ({
      ...prevData,
      serviceDetails: prevData.serviceDetails.filter(
        (service) => service.serviceId != removeService
      ),
      totalAmount: (prevData.totalAmount - price)
    }));
  }
  function handleServiceCardMouseOver() {
    serviceCard.current.style.boxShadow = "0px 5px 20px #ffc55a";
  }
  function handleServiceCardMouseOut() {
    serviceCard.current.style.boxShadow = "0px 3px 5px -1px #ffc55a";
  }
  function handleServiceCardButtonClick() {
    if (!serviceCardBorderToggler) {
      serviceCardButton.current.style.backgroundColor = "#ffc55a";
      serviceCardButton.current.style.borderColor = "#ead8b1";
      serviceCard.current.style.border = "1px #ffc55a groove";
      serviceCard.current.style.backgroundColor = "#ffc55a";
      serviceCard.current.style.transform = "rotate(2deg)";
      serviceCardSelector.current.style.display = "inline";
      setBadgeValue(badgeValue + 1);
      addService(props.serviceId, props.price, props.name);
    } else {
      serviceCardButton.current.style.backgroundColor = "#ead8b1";
      serviceCardButton.current.style.borderColor = "#ffc55a";
      serviceCard.current.style.border = "none";
      serviceCard.current.style.transform = "rotate(0deg)";
      serviceCard.current.style.backgroundColor = "#ead8b1";
      serviceCardSelector.current.style.display = "none";
      setBadgeValue(badgeValue - 1);
      removeService(props.serviceId, props.price);
    }
    setServiceCardBorderToggler(!serviceCardBorderToggler);
  }
  useEffect(() => {
    cartDetails.serviceDetails.map((service) => {
      if (serviceCardButton.current.id == service.serviceId) {
        serviceCardButton.current.style.backgroundColor = "#ffc55a";
        serviceCardButton.current.style.borderColor = "#ead8b1";
        serviceCard.current.style.border = "1px #ffc55a groove";
        serviceCard.current.style.backgroundColor = "#ffc55a";
        serviceCard.current.style.transform = "rotate(2deg)";
        serviceCardSelector.current.style.display = "inline";
        setServiceCardBorderToggler(true);
      }
    });
    setBadgeValue(cartDetails.serviceDetails.length);
  }, []);
  return (
    <div
      id="service_cart"
      ref={serviceCard}
      onMouseOver={handleServiceCardMouseOver}
      onMouseOut={handleServiceCardMouseOut}
    >
      {/* <span class="" ref={serviceCardSelector}>🤍</span> */}
      {/* <span class="" ref={serviceCardSelector}>Added to cart</span> */}
      <i className="fa-solid fa-check-double" ref={serviceCardSelector}></i>
      <div id="service_cart_header"></div>
      <div id="service_cart_footer" ref={serviceCardFooter}>
        {/* <div>{"₹ " + props.price}</div> */}
        <div>{props.name}</div>
        <button
          id={props.serviceId}
          ref={serviceCardButton}
          onClick={handleServiceCardButtonClick}
        >
          Add to cart
        </button>
      </div>
    </div>
  );
}

export default ServiceCard;
