import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import "../style/cart.css";
import { HorizontalDivider } from "./HorizontalDivider";
import DialogBox from "./DialogBox.jsx";
import axios from "axios";

export function Cart(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const cart = useRef(null);
  const { cartDetails, setCartDetails } = useContext(CartContext);
  const [dialogBox, setDialogBox] = useState({});
  // console.log(dialogBox)
  function closeCart() {
    cart.current.style.display = "none";
    // if (props.navigate !== null) {
    //   navigate(props.navigate);
    // }
    // document.getElementById("booking_picker").style.display = "block";
    // const dialogBox = document.getElementById("dialogBox");
    // setDialogBox({
    //   type: "booking date and time",
    //   title: "Hi gorgious,",
    //   definition: "Please select the date and time.",
    //   buttonTitle: "Sure!",
    // });
    // dialogBox.style.display = "block";
  }
  // Close the modal if clicking outside of the modal content
  function handleClickOutsideModal(event) {
    if (event.target === cart.current) {
      //   bookingPicker.current.style.display = "none";
      closeCart();
    }
  }
  window.addEventListener("click", handleClickOutsideModal);
  function handleBooking() {
    if (cartDetails.serviceDetails.length != 0) {
      axios
        .post(`${apiUrl}/protected/booking`, cartDetails, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            setCartDetails((prevData) => ({
              ...prevData,
              bookingTime: "",
              serviceDetails: [],
              totalAmount: 0,
            }));
            const dialogBox = document.getElementById("dialogBox");
            setDialogBox({
              type: "booking success",
              title: "Hey! beauty, Booking Successful",
              definition: "We will call for your timings",
              buttonTitle: "Thank you",
            });
            dialogBox.style.display = "block";
          } else {
            alert("Something wrong");
            navigate("/home");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            const dialogBox = document.getElementById("dialogBox");
            setDialogBox({
              type: "issue 401",
              title: "Sorry Beauty,",
              definition:
                "There is some tech issue, we will connect you soon!!",
              buttonTitle: "Try Again",
            });
            dialogBox.style.display = "block";
          } else {
            const dialogBox = document.getElementById("dialogBox");
            setDialogBox({
              type: "issue",
              title: "Sorry Beauty,",
              definition:
                "There is some tech issue, we will connect you soon!!",
              buttonTitle: "Try Again",
            });
            dialogBox.style.display = "block";
          }
        });
    } else {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "empty",
        title: "Beauty, your cart is empty",
        definition: "Add some service to serve your beauty!!",
        buttonTitle: "Add",
      });
      dialogBox.style.display = "block";
    }
  }
  return (
    // Popup dialog box
    <div ref={cart} id="cart" className="modal_cart">
      <div className="modal-content_cart" style={{ marginBottom: "0" }}>
        <span id="closeCart" className="close_cart" onClick={closeCart}>
          &times;
        </span>
        <div id="cart_details">
          <ul>
            {" "}
            {cartDetails.serviceDetails.map((service, index) => {
              return (
                <li>
                  <p key={index}>{service.name}</p>
                  {/* <p>{"₹ " + service.price}</p> */}
                </li>
              );
            })}
          </ul>

          <HorizontalDivider></HorizontalDivider>
          {/* <div id="amount">
            <h6>Total amount to pay at the time of visit:</h6>
            <h6>{"₹ " + cartDetails.totalAmount}</h6>
          </div> */}
        </div>
        <DialogBox
          type={dialogBox.type}
          title={dialogBox.title}
          definition={dialogBox.definition}
          buttonTitle={dialogBox.buttonTitle}
          navigate={dialogBox.navigate}
        />
        <button onClick={handleBooking} id="cancelBtn_cart">
          {props.buttonTitle}
        </button>
      </div>
    </div>
  );
}
