import { useRef, useContext, useState } from "react";
import logo from "../assets/BeautyOne.gif";
import MenuOptions from "./MenuOptions";
import MenuOptionsLargeScreen from "./MenuOptionsLargeScreen";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { UserContext } from "../context/UserContext.js";
import { Badge, Avatar } from "@mui/material";
import { CartBadgeContext } from "../context/CartBadgeContext.js";
import "../style/header.css";

function Header(props) {
  const navigate = useNavigate();
  const { userDetailsContext } = useContext(UserContext);
  const { badgeValue } = useContext(CartBadgeContext);
  const position = {
    menuOptions: {
      right: "30px",
      top: "70px",
    },
  };
  const menuBack = useRef(null);
  let menuToggleFlag = false;
  function menuToggle() {
    menuToggleFlag = !menuToggleFlag;
    console.log(menuBack.current);
    menuToggleFlag
      ? (menuBack.current.style.display = "flex")
      : (menuBack.current.style.display = "none");
  }
  // Close the modal if clicking outside of the modal content
  window.onclick = function (event) {
    if (event.target === menuBack.current) {
      menuToggle();
    }
  };
  function handleClick() {
    navigate("/");
  }
  const profileStyle = {
    fontSize: "1em",
    color: "#ffc55a",
    border: "3px #ffc55a groove",
    padding: "10px",
    borderRadius: "50%",
    backgroundColor: "white",
  };

  const sideBarData = {
    // name: userDetailsContext.user_name,
    bookings: "Your Bookings",
    cart: "Your Cart",
    bookedSlots: "Booked Slots",
    logout: "Log Out"
  };

  function handleProfileClick() {
    document.getElementById("sideBar").style.display = "inline";
  }
  return (
    <div id="header">
      <div id="logo" onClick={handleClick}>
        <img src={logo} alt="" />
      </div>
      {props.auth ? (
        <div>
          <SideBar data={sideBarData} showCart = {props.showCart} showYourBooking={props.showYourBooking}/>
          <Badge
            badgeContent={badgeValue}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            slotProps={{ badge: { className: "my-badge" } }}
          >
            {/* <i
              onClick={handleProfileClick}
              style={profileStyle}
              class="fa-solid fa-user"
            ></i> */}
            <Avatar
              alt={userDetailsContext.user_name}
              src="../assets/profile.jpg"
              onClick={handleProfileClick}
              style={profileStyle}
            />
          </Badge>
          <div />
        </div>
      ) : (
        <div id="menu">
          <i onClick={menuToggle} className="fa-solid fa-ellipsis-vertical"></i>
          <MenuOptions
            id="headerOptions"
            ref={{ menuBack }}
            options={props.menuOptionsProps}
            style={position}
          />
          <MenuOptionsLargeScreen options={props.menuOptionsProps} />
        </div>
      )}
    </div>
  );
}

export default Header;
