import React, { createContext, useState, useEffect } from "react";

// Create context
const CartBadgeContext = createContext();

// Create provider component
function CartBadgeProvider({ children }) {
  const [badgeValue, setBadgeValue] = useState(0);

  return (
    <CartBadgeContext.Provider value={{ badgeValue, setBadgeValue }}>
      {children}
    </CartBadgeContext.Provider>
  );
}

export { CartBadgeContext, CartBadgeProvider };
