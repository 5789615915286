import InputField from "./InputField";
import PasswordField from "./PasswordField";
import Button from "./Button";
import Header from "./Header";
import Footer from "./Footer";
import { useState, useContext } from "react";
import { UserContext } from "../context/UserContext.js";
import DialogBox from "./DialogBox.jsx";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Loader from "./Loader.jsx";
import { waitForElement } from "../utility/ElementsService.js";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
  });
  const [dialogBox, setDialogBox] = useState({});
  const alignMent = {
    alignSelf: "end",
    position: "relative",
    right: "10px",
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const { setUserDetailsContext } = useContext(UserContext);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const menuOptionsProps = [
    {
      name: "Sign Up",
      address: "/signup",
    },
    {
      name: "Services",
      address: "/services",
    },
    {
      name: "About",
      address: "/about",
    },

    {
      name: "Contact",
      address: "/contact",
    },
  ];
  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    setUserDetails((prev) => {
      if (name === "username") {
        return {
          username: value,
          password: prev.password,
        };
      } else if (name === "password") {
        return {
          username: prev.username,
          password: value,
        };
      }
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (userDetails.username === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "email",
        title: "E-mail is empty",
        definition: "Please fill your email",
        buttonTitle: "OK",
      });
      dialogBox.style.display = "block";
    } else if (!validateEmail(userDetails.username)) {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "email",
        title: "E-mail is invalid",
        definition: "Please enter valid email",
        buttonTitle: "OK",
      });
      dialogBox.style.display = "block";
    } else if (userDetails.password === "") {
      const dialogBox = document.getElementById("dialogBox");
      setDialogBox({
        type: "password",
        title: "Password is not enter",
        definition: "Please enter the password...",
        buttonTitle: "OK",
      });
      dialogBox.style.display = "block";
    } else {
      setLoading(true);
      axios
        .post(`${apiUrl}/login`, userDetails, { withCredentials: true })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            console.log(response);
            // After successful login
            // const token = response.data.token; //fetching token value from the response given by the server
            // Cookies.set("token", token, { expires: 1, path: "/" }); // Cookie expires in 1 day and setting token to the cookies of react app
            setIsAuthenticated(true);
            setUserDetailsContext(response.data.user);
            // window.location.reload();
            // navigate('/',{replace: true});
            navigate("/home");
          } else {
            alert("Something wrong");
            navigate("/login");
          }
        })
        .catch(async (err) => {
          setLoading(false);
          console.log(err);
          if (err.response.status === 401) {
            const dialogBox = await waitForElement("#dialogBox");
            setDialogBox({
              type: "idpsd",
              title: "Id / Password",
              definition: "Id or Password is wrong!!, please try again",
              buttonTitle: "Try Again",
            });
            dialogBox.style.display = "block";
            setUserDetails({
              username: "",
              password: "",
            });
          } else {
            const dialogBox = await waitForElement("#dialogBox");
            setDialogBox({
              type: "unregistered",
              title: "Unregistered User",
              definition: "You are not registered, Plz create your account.",
              buttonTitle: "OK",
              navigate: "/signup",
            });
            dialogBox.style.display = "block";
          }
        });
    }
  }
  // window.addEventListener("keydown", (event) => {
  //   event.preventDefault();
  //   if (event.code === "Enter") {
  //     // if (userDetails.username === "" || userDetails.password === "") {
  //     //   const dialogBox = document.getElementById("dialogBox");
  //     //   setDialogBox({
  //     //     type: "Empty",
  //     //     title: "Empty",
  //     //     definition: "Fill id and password",
  //     //     buttonTitle: "OK",
  //     //   });
  //     //   dialogBox.style.display = "block";
  //     // }else {
  //     //   console.warn("I got called");
  //     //   handleSubmit(event);
  //     // }
  //     const dialogBox = document.getElementById("dialogBox");
  //     setDialogBox({
  //       type: "enter",
  //       title: "Please press Sign In button",
  //       definition: "Enter is not allowed",
  //       buttonTitle: "OK",
  //     });
  //     dialogBox.style.display = "block";
  //   }
  // });

  if (loading) return <Loader />;
  return (
    <div>
      <Header menuOptionsProps={menuOptionsProps} />
      <form id="sign_in">
        <div>
          <h1>Sign In</h1>
          <div>
            <InputField
              id="email"
              input="email"
              label="E-mail"
              placeholder="E-mail"
              name="username"
              value={userDetails.username}
              onChange={handleChange}
            />
            <PasswordField
              id="password"
              label="Password"
              placeholder="Password"
              name="password"
              value={userDetails.password}
              onChange={handleChange}
            />
          </div>
          <Button style={alignMent} title="Sign In" onClick={handleSubmit} />
          <div id="circle"></div>
          <DialogBox
            type={dialogBox.type}
            title={dialogBox.title}
            definition={dialogBox.definition}
            buttonTitle={dialogBox.buttonTitle}
            navigate={dialogBox.navigate}
          />
        </div>
      </form>
      <Footer />
      <br />
    </div>
  );
}
export default SignIn;
